import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { defaultDateFormat, defaultISODateFormat } from 'shared/constants';
import { IconCloudDownload, IconPopup, IconShifts } from 'shared/Icons';
import view1 from 'shared/Icons/workforce/list-view-active.png';
import view2 from 'shared/Icons/workforce/grid-view-active.png';
import userGreen from 'shared/Icons/workforce/user-green.png';
import { ContentLoader } from 'shared';
import api from 'helpers/api';
import './styles.scss';

import { getAllDocumentTypes, getWorkerDetails, getWorkerDocuments } from './actions';
import { getWorkerVacations, getAbsence } from './components/AbsencesAndVacations/actions';

import ManageDocumentsModal from './components/ManageDocumentsModal/ManageDocumentsModal';
import PDFPreviewModal from '../IndustryProjectDetails/components/PDFPreviewModal';
import AllContractsModal from './components/AllContractsModal/AllContractsModal';
import WorkerWorkingPlaces from './components/WorkerWorkingPlaces/index';
import AbsencesAndVacations from './components/AbsencesAndVacations/AbsencesAndVacations';
import WorkingInjuriesTab from './components/WorkingInjuriesTab';
import WorkerSchedulesTab from './components/WorkerSchedulesTab';
import GeneralInfoTab from './components/GeneralInfoTab/index';
import MyForms from './components/MyForms/MyForms';

const IndustryLocationWorkerDetails = ({ locationId, companyId, t, history, currentUser, showWorkingInjuries }) => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [currentUserRole, setCurrentUserRole] = useState('Voditelj');
  const [workerId, setWorkerId] = useState();
  const [workerDetails, setWorkerDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDocumentationModal, setShowDocumentationModal] = useState(false);
  const [pdfPreviewModalState, setPdfPreviewModalState] = useState(false);
  const [pdfPreviewModalObject, setPdfPreviewModalObject] = useState(null);
  const [showAllContracts, setShowAllContracts] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [contractsTableData, setContractsTableData] = useState({
    contract: [],
    isLoading: true,
  });
  const [lastContract, setLastContract] = useState({});
  const [showAbsenceModal, setShowAbsenceModal] = useState(false);
  const [workerAbsencesAndVacations, setWorkerAbsencesAndVacations] = useState({
    usedDays: 0,
    assignedDays: 0,
    absencesCount: 0,
  });

  const fetchWorkersList = () => {
    getWorkerDetails(locationId, companyId, workerId)
      .then((resp) => {
        const worker = get(resp, 'data', []);
        if (worker) {
          setWorkerDetails(worker);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const fetchDocumentTypes = async () => {
    const res = await getAllDocumentTypes(companyId);
    setDocumentTypes(get(res, 'data.results', []));
  };

  const fetchContracts = async () => {
    setContractsTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const filters = '&is_mid=true&order_by=-updated_at';
    const res = await getWorkerDocuments(companyId, workerId, filters);
    const contractsData = get(res, 'data.results', []);
    setLastContract(contractsData[0]);
    setContractsTableData({
      contracts: contractsData,
      isLoading: false,
    });
  };

  const fetchWorkerVacationDays = async () => {
    await getWorkerVacations(companyId, workerId, `&year=${moment().format('YYYY')}&order_by=-updated_at`)
      .then((res) => {
        const usedDays = get(res, 'data.results[0].used_days', 0);
        const assignedDays = get(res, 'data.results[0].assigned_days', 0);
        setWorkerAbsencesAndVacations((prevState) => ({
          ...prevState,
          usedDays,
          assignedDays,
        }));
      });
  };

  const fetchWorkerAbsences = async () => {
    await getAbsence(companyId, workerId, `&limit=9999&order_by=-updated_at&absence_type=sick-leave&start_date=${moment().subtract(3, 'years').startOf('year').format(defaultISODateFormat)}&end_date=${moment().endOf('year').format(defaultISODateFormat)}`)
      .then((res) => {
        const absences = get(res, 'data.results', []);
        const totalDays = absences.reduce((acc, absence) => acc + (absence.number_of_days || 0), 0);

        setWorkerAbsencesAndVacations((prevState) => ({
          ...prevState,
          absencesCount: totalDays,
        }));
      });
  };

  const updateAbsenceAndVacationDays = () => {
    fetchWorkerVacationDays();
    fetchWorkerAbsences();
  };

  useEffect(() => {
    if (documentTypes?.length) {
      fetchContracts();
    }
  }, [documentTypes]);

  const checkRoles = async () => {
    setLoading(true);

    if (currentUser && !currentUser?.is_admin) {
      const hrRole = currentUser?.roles.find((obj) => obj.role?.name?.toLowerCase() === 'hr');

      if (hrRole) {
        setCurrentUserRole('HR');
        setIsReadOnly(false);
        setLoading(false);
      } else {
        try {
          const response = await api.get(`/api/v1/workforce/workers/?company=${companyId}&location=${locationId}&user=${currentUser?.id}`);
          const currentUserWorkerID = get(response, 'data.results[0].id', null);
          if (currentUserWorkerID) {
            // Make an API call to check if the user is a manager or deputy manager
            const data = await api.get(`/api/v1/workforce/department_managers/?location=${locationId}&worker=${currentUserWorkerID}&is_active=true`);
            const results = get(data, 'data.results', null);
            const isManager = results.some((manager) => manager.is_manager || manager.is_deputy_manager);

            if (isManager) {
              setCurrentUserRole('Voditelj');
              setIsReadOnly(true);
            } else {
              setCurrentUserRole('');
              setIsReadOnly(true);
            }
          }
        } catch (error) {
          setLoading(false);
          setCurrentUserRole('');
          setIsReadOnly(true);
        } finally {
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
      setCurrentUserRole('');
      setIsReadOnly(true);
    }

    if (currentUser.is_admin) {
      setIsReadOnly(false);
      setCurrentUserRole('');
    }
  };

  useEffect(() => {
    fetchDocumentTypes();
  }, [currentUser]);

  useEffect(() => {
    if (workerId && currentUser) {
      checkRoles();
      fetchWorkerVacationDays();
      fetchWorkerAbsences();
    }
  }, [currentUser, workerId]);

  useEffect(() => {
    const getWorkerId = () => {
      const url = window.location.href;
      const urlParts = url.split('/');
      setWorkerId(parseInt(urlParts[urlParts.length - 1], 10));
    };

    if (!workerId) {
      getWorkerId();
    } else {
      fetchWorkersList();
    }
  }, [workerId]);

  const closePDFPreviewModal = () => {
    setPdfPreviewModalObject({});
    setPdfPreviewModalState(false);
  };

  const handleClickDownloadDocument = () => {
    const link = document.createElement('a');
    link.href = lastContract.file;
    link.target = '_blank';
    link.setAttribute('download', lastContract.name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  if (loading && !workerId) {
    return (
      <div className="loaderWrapper">
        <ContentLoader />
      </div>
    );
  }

  const transformNameOrSurnameString = (string) => {
    if (!string) {
      return '';
    }
    const deconstructedString = string.split(/\s+|-/);
    const formatedString = deconstructedString.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');

    return formatedString;
  };

  return (
    <div className="workerDetails_container fullscreen">
      {!(isReadOnly && currentUserRole === '') &&
        <div className="breadcrumbs">
          <span
            onClick={() => {
              history.push(`/${companyId}/industry/location/${locationId}/human-resources/`);
            }}
          >{t('page_content.workforce_management.worker_details.breadcrumb_workers_list')}</span>
          <p>{'>'}</p>
          <span>{`${transformNameOrSurnameString(workerDetails.name)} ${transformNameOrSurnameString(workerDetails.last_name)}`}</span>
        </div>}

      <div className="info_row">
        <div className="info_card">
          {loading ? <div className="card_loader"><ContentLoader text="" /></div> :
            <div style={{ display: 'flex', gap: 20 }}>
              <div className="leftColumn">
                <img src={workerDetails && workerDetails?.profile_picture?.thumbnail ? workerDetails?.profile_picture?.thumbnail : userGreen} alt="" />
              </div>
              <div className="rightColumn">
                <div className="title">{workerDetails ? `${workerDetails.name} ${workerDetails.last_name}` : ''}</div>
              </div>
            </div>}
        </div>

        <div className="info_card">
          <div className="column">
            <div className="inline">
              <div className="leftColumn">
                <img src={view1} alt="" />
              </div>
              <div className="rightColumn">
                <div className="title">{t('page_content.workforce_management.worker_details.card_work_contract')}</div>
              </div>
            </div>
            <div className="date">
            {lastContract?.created_at && `${t('page_content.workforce_management.worker_details.card_last_updated')}: ${moment(lastContract?.created_at).format(defaultDateFormat)}`}
            </div>
            <div className="icons">
              <div className={`${isReadOnly ? 'icon_button disabled_icon_button' : 'icon_button'}`} onClick={() => !isReadOnly && setShowAllContracts(true)}>
                <IconPopup height="15px" width="15px" /> {t('page_content.workforce_management.worker_details.all_contracts_button')}
              </div>
              <Tooltip
                trigger={!lastContract ? 'click' : 'none'}
                placement="top"
                overlay={<div>{t('page_content.workforce_management.worker_details.tooltip_message_download')}</div>}
                overlayClassName="tooltip_overlay"
              >
                <div className={`${!lastContract ? 'icon_button disabled_icon_button' : 'icon_button'}`} onClick={lastContract && handleClickDownloadDocument}>
                  <IconCloudDownload height="15px" width="15px" color={`${!lastContract ? '#979797' : '#555'}`} /> {t('page_content.workforce_management.worker_details.download_document_button')}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="info_card">
          <div className="column">
            <div className="inline">
              <div className="leftColumn">
                <img src={view2} alt="" />
              </div>
              <div className="rightColumn">
                <div className="title">{t('page_content.workforce_management.worker_details.card_other_documentations')}</div>
              </div>
            </div>
            <div className="icons">
              <div className={`${isReadOnly ? 'icon_button disabled_icon_button' : 'icon_button'}`} onClick={() => !isReadOnly && setShowDocumentationModal(true)}>
                <IconPopup height="15px" width="15px" color="#555" /> {t('page_content.workforce_management.worker_details.manage_documents_button')}
              </div>
            </div>
          </div>
        </div>

        <div className="info_card">
          <div className="column">
            <div className="inline">
              <div className="leftColumn" style={{ padding: 14 }}>
                <IconShifts fill="#7bc7be" height="50px" width="50px" />
              </div>
              <div className="rightColumn">
                <div className="description">
                  <span className="text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.remaining_total_vacation')}:</span>
                  <span className="numbers">{`${workerAbsencesAndVacations?.usedDays || 0}/${workerAbsencesAndVacations?.assignedDays || 0}`}</span>
                </div>
                <div className="description">
                  <span className="text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.sick_leave_last_three_years')}:</span>
                  <span className="numbers">{workerAbsencesAndVacations?.absencesCount}</span>
                </div>
              </div>
            </div>
            <div className="icons">
              <div className={`${isReadOnly ? 'icon_button disabled_icon_button' : 'icon_button'}`} onClick={() => !isReadOnly && setShowAbsenceModal(true)}>
                <IconPopup height="15px" width="15px" color="#555" /> {t('page_content.workforce_management.worker_details.worker_absence_vacation_card.button_view')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tab_pannels">
        <Tabs>

          <TabList>
            <Tab>{t('page_content.workforce_management.worker_details.tab_general')}</Tab>
            <Tab>{t('page_content.workforce_management.worker_details.tab_my_forms')}</Tab>
            {showWorkingInjuries && <Tab>{t('page_content.human_resources.injuries.working_injuries')}</Tab>}
            <Tab>{t('page_content.workforce_management.worker_details.worker_scheduler')}</Tab>
            <Tab>{t('page_content.workforce_management.worker_details.working_places_tab.working_places_tab_title')}</Tab>
          </TabList>

          <TabPanel>
            <GeneralInfoTab
              companyId={companyId}
              isReadOnly={isReadOnly}
              workerDetails={workerDetails}
              transformString={transformNameOrSurnameString}
              currentUserRole={currentUserRole}
            />
          </TabPanel>

          <TabPanel>
            <MyForms
              currentUser={currentUser}
              companyId={companyId}
              workerId={workerId}
            />
          </TabPanel>

          {
            showWorkingInjuries &&
              <TabPanel>
                <WorkingInjuriesTab
                  locationId={locationId}
                  workerId={workerId}
                />
              </TabPanel>
          }

          <TabPanel>
            <WorkerSchedulesTab
              isReadOnly={isReadOnly}
              companyId={companyId}
              workerId={workerId}
              workerDetails={workerDetails}
            />
          </TabPanel>

          <TabPanel>
            <WorkerWorkingPlaces
              workerId={workerId}
              companyId={companyId}
              locationId={locationId}
              isReadOnly={isReadOnly}
              currentUser={currentUser}
              workerDetails={workerDetails}
            />
          </TabPanel>

        </Tabs>
      </div>

      {
        showAbsenceModal &&
          <AbsencesAndVacations
            workerId={workerId}
            companyId={companyId}
            isReadOnly={isReadOnly}
            currentUser={currentUser}
            showAbsenceModal={showAbsenceModal}
            handleClose={() => setShowAbsenceModal(false)}
            workerAbsencesAndVacations={workerAbsencesAndVacations}
            updateAbsenceAndVacationDays={updateAbsenceAndVacationDays}
          />
      }

      <ManageDocumentsModal
        workerId={workerId}
        companyId={companyId}
        isReadOnly={isReadOnly}
        currentUser={currentUser}
        refetchDocumentTypes={fetchContracts}
        showDocumentationModal={showDocumentationModal}
        handleClose={() => setShowDocumentationModal(false)}
      />

      <AllContractsModal
        workerId={workerId}
        companyId={companyId}
        isReadOnly={isReadOnly}
        showModal={showAllContracts}
        tableData={contractsTableData}
        handleCloseModal={() => setShowAllContracts(false)}
      />

      <PDFPreviewModal
        closeModal={closePDFPreviewModal}
        modalStatus={pdfPreviewModalState}
        pdfObjectInfo={pdfPreviewModalObject}
      />
    </div>
  );
};

IndustryLocationWorkerDetails.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  showWorkingInjuries: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    showWorkingInjuries: get(state, 'app.companyConfig.config.working_injuries', false),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationWorkerDetails)));
