import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Table, Button, TableButtons } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { IconEdit } from 'shared/Icons';
import '../../../styles.scss';

import {
  getDataDefinitions,
  addPersonnelDataRating,
  getPersonnelDataRatings,
  getPaginatedDataDefinitions,
  refreshWorkerPersonnelValues,
} from '../../../actions';

const PersonnelDataRating = ({ selectedWorker, currentUser, companyId, t }) => {
  const [personnelData, setPersonnelData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [additionalData, setAdditionalData] = useState({
    definitions: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [newScoring, setNewScoring] = useState({});
  const currentYear = moment().format('YYYY');
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'order',
    year: null,
  });

  const [addedScore, setAddedScore] = useState([]);

  const fetchPersonnelDataRatings = async () => {
    setPersonnelData((prevState) => ({ ...prevState, isLoading: true }));

    let apiFilters = `&limit=9999&order_by=-updated_at&worker=${selectedWorker.id}`;

    if (filters?.year) apiFilters += `&year=${filters.year}&is_active=false`;
    else apiFilters += '&is_active=true';

    await getPersonnelDataRatings(companyId, apiFilters)
      .then((res) => {
        const result = get(res, 'data.results', []);
        setPersonnelData({
          data: result,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setPersonnelData((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const fetchDataDefinitions = async () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&is_active=true&order_by=${asc}${filters?.selectedSort}`;

    await getDataDefinitions(companyId, apiFilters)
      .then((res) => {
        setAdditionalData({
          definitions: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAdditionalData((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const fetchPaginatedDataDefinitions = async (url) => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    await getPaginatedDataDefinitions(url)
      .then((res) => {
        setAdditionalData({
          definitions: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAdditionalData((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const handleRefreshAutomaticValues = () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));
    refreshWorkerPersonnelValues(companyId, currentYear, selectedWorker.id)
      .then(() => fetchDataDefinitions())
      .catch(() => setAdditionalData((prevState) => ({ ...prevState, isLoading: true })));
  };

  useEffect(() => {
    handleRefreshAutomaticValues();
  }, [filters]);

  useEffect(() => {
    fetchPersonnelDataRatings();
  }, [additionalData.definitions]);

  useEffect(() => {
    if (additionalData?.definitions && personnelData?.data) {
      const newAddedScore = !filters?.year ?
        (
          additionalData?.definitions?.map((definition) => {
            const score = personnelData?.data?.find((item) => item?.definition?.id === definition.id);
            return {
              ...definition,
              definition_id: definition?.id,
              score_id: score?.id || null,
              score: score?.score || '-',
              year: score?.year || '-',
              score_updated_at: score?.updated_at || null,
              value: score?.value || '-',
            };
          })
        ) : (
          additionalData?.definitions?.flatMap((definition) => {
            const scores = personnelData?.data?.filter((item) => item?.definition?.id === definition.id);
            const score = scores?.map((score1) => {
              return {
                ...definition,
                definition_id: definition?.id,
                score_id: score1?.id || null,
                score: score1?.score || '-',
                year: score1?.year || '-',
                score_updated_at: score1?.updated_at || null,
                value: score1?.value || '-',
              };
            });
            return score.length ?
              score : {
                ...definition,
                definition_id: definition?.id,
                score_id: null,
                score: '-',
                year: '-',
                score_updated_at: null,
                value: '-',
              };
          })
        );
      setAddedScore(newAddedScore);
    }
  }, [additionalData.definitions, personnelData.data, filters.year]);

  const handleChangeScoring = (score_id, key, value) => {
    if (!value) {
      setNewScoring((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    } else {
      setNewScoring((prevState) => ({
        ...prevState,
        [key]: { ...value, score_id },
      }));
    }
  };

  const handleSubmitAll = () => {
    if (!selectedWorker?.id || !currentUser?.id || !newScoring || Object.keys(newScoring).length === 0) return;

    const data = Object.entries(newScoring).map(([key, value]) => ({
      score_id: value?.score_id,
      definition: key,
      value: value?.label,
      worker: selectedWorker.id,
      user: currentUser.id,
      year: currentYear,
    }));

    if (data.some((item) => !item.value || !item.year || !item.definition)) return;

    setPersonnelData((prevState) => ({ ...prevState, isLoading: true }));

    Promise.all(data.map((item) => addPersonnelDataRating(companyId, item)))
      .then(() => { setNewScoring({}); fetchPersonnelDataRatings(); })
      .catch(() => { setPersonnelData((prevState) => ({ ...prevState, isLoading: false })); });
  };

  const handleEditValue = (row) => {
    const newData = personnelData?.data?.map((item) => {
      if (item?.definition?.id === row?.definition_id) {
        setNewScoring((prevState) => ({
          ...prevState,
          [row?.definition_id]: { label: item?.value, value: item?.score, score_id: item?.id },
        }));
        return {
          ...item,
          value: '-',
          score: '-',
        };
      }
      return item;
    });
    setPersonnelData((prevState) => ({ ...prevState, data: newData }));
  };

  const handleRemoveEdit = (row) => {
    setPersonnelData((prevState) => ({
      ...prevState,
      data: prevState.data.map((item) => {
        if (item?.definition?.id === row?.definition_id) {
          return {
            ...item,
            value: newScoring?.[row?.definition_id]?.label,
            score: newScoring?.[row?.definition_id]?.value,
          };
        }
        return item;
      }),
    }));
    setNewScoring((prevState) => {
      const newState = { ...prevState };
      delete newState[row?.definition_id];
      return newState;
    });
  };

  const handleYearChange = (value) => {
    setFilters({ ...filters, year: value });
  };

  const totalScore = addedScore?.reduce((total, item) => total + (item.score === '-' ? 0 : item.score), 0);

  const columns = [
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_definition')}</span>,
      accessor: 'name',
      minWidth: 300,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_updated_at')}</span>,
      accessor: 'score_updated_at',
      Cell: (row) => (row?.value ? moment(row.value)?.format(defaultDateFormat) : '-'),
      width: 150,
      sortable: false,
      style: {
        cursor: 'default',
      },
    },

    !filters?.year ?
      (
        {
          Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_value')}</span>,
          Footer: () => newScoring && Object.keys(newScoring).length > 0 && (
            <div style={{ width: '100%', display: 'flex' }}>
              <Button style={{ marginLeft: 'auto' }} type="add" onClick={handleSubmitAll}>
                {t('page_content.human_resources.rating.personnel_data_tab.button_placeholder_submit_all')}
              </Button>
            </div>),
          accessor: 'scoring',
          Cell: (row) => {
            if (!filters?.year && !row?.original?.is_predefined && row?.value?.values && row?.original?.score === '-' && row?.original?.value === '-') {
              const selectedOption = newScoring?.[row?.original?.definition_id];
              if (row?.value?.type === 'map') {
                const selectOptions = Object.entries(row?.value?.values)?.map(([key, value]) => ({ label: key, value }));
                return (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <div style={selectedOption?.score_id ? { width: '95%' } : { width: '100%' }}>
                      <Select
                        options={selectOptions || []}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isClearable
                        isDisabled={additionalData?.isLoading || personnelData?.isLoading}
                        placeholder={t('page_content.human_resources.rating.personnel_data_tab.select_placeholder_value')}
                        menuPosition="fixed"
                        onChange={(e) => handleChangeScoring(row.original?.score_id, row?.original?.definition_id, e || '')}
                        value={selectOptions?.find((val) => val === selectedOption?.value || val.label === selectedOption?.label) || null}
                        styles={selectModalStyles}
                      />
                    </div>
                {
                  selectedOption?.score_id && (
                    <div style={{ cursor: 'pointer' }} onClick={() => handleRemoveEdit(row.original)}>
                      <Button style={{ padding: '4px 8px' }}>{t('page_content.human_resources.rating.personnel_data_tab.button_placeholder_cancel')}</Button>
                    </div>
                  )
                }
              </div>
                );
              }
              if (row?.value?.type === 'range') {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <div style={selectedOption?.score_id ? { width: '95%' } : { width: '100%' }}>
                      <input
                        className="range_input"
                        type="text"
                        disabled={additionalData?.isLoading || personnelData?.isLoading}
                        value={selectedOption?.label || ''}
                        onChange={(e) => handleChangeScoring(row.original?.score_id, row?.original?.definition_id, e.target.value ? { value: e.target.value, label: e.target.value } : '')}
                        placeholder={t('page_content.human_resources.rating.personnel_data_tab.input_placeholder_score')}
                      />
                    </div>
                    {
                      row?.original?.year === currentYear && selectedOption?.score_id && (
                        <div style={{ cursor: 'pointer' }} onClick={() => handleRemoveEdit(row.original)}>
                          <Button style={{ padding: '4px 8px' }}>{t('page_content.human_resources.rating.personnel_data_tab.button_placeholder_cancel')}</Button>
                        </div>
                      )
                    }
                  </div>
                );
              }
            }
            return (
              <div style={{ display: 'flex', gap: 10, padding: 5 }}>
                {row?.original?.value || '-'}
                {
                  !filters?.year && row?.original?.year === Number(currentYear) && !row?.original?.is_predefined &&
                  <div style={{ cursor: 'pointer' }} onClick={() => row?.original?.year === Number(currentYear) && handleEditValue(row.original)}>
                    <IconEdit
                      height="14px"
                      width="14px"
                    />
                  </div>
                }
              </div>
            );
          },
          minWidth: 300,
          sortable: false,
          style: {
            cursor: 'default',
          },
        }
      ) : (
        {
          Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_value')}</span>,
          accessor: 'scoring',
          Cell: (row) => {
            return (
              <div style={{ display: 'flex', gap: 10, padding: 5 }}>
                {row?.original?.value || '-'}
              </div>
            );
          },
          minWidth: 300,
          sortable: false,
          style: {
            cursor: 'default',
          },
        }
      ),

    !filters?.year ?
      (
        {
          Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_score')}</span>,
          Footer: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_footer_total')}: {totalScore}</span>,
          accessor: 'score',
          width: 125,
          sortable: false,
          style: {
            cursor: 'default',
          },
        }
      ) : (
        {
          Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_score')}</span>,
          accessor: 'score',
          width: 125,
          sortable: false,
          style: {
            cursor: 'default',
          },
        }
      ),
  ];

  return (
    <div className="ratings__screen">
      <div className="ratings__screen__filters">
        <div className="double_box">
          <span style={{ fontSize: '13px', color: '#555', fontWeight: '700' }}>{t('page_content.human_resources.rating.personnel_data_tab.filter_by_year')}:</span>
          <DatePicker
            selected={filters?.year ? moment(filters?.year, 'YYYY').toDate() : null}
            onChange={(date) => handleYearChange(date ? moment(date).format('YYYY') : null)}
            placeholderText={t('YYYY')}
            showYearPicker
            dateFormat="yyyy"
            maxDate={moment().toDate()}
          />
          <Button
            onClick={() => setFilters({ ...filters, year: null })}
            disabled={!filters?.year}
          >
            {t('shared.clear_one_button')}
          </Button>
        </div>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={columns}
        data={addedScore}
        defaultPageSize={15}
        loading={personnelData.isLoading || additionalData.isLoading}
        minRows={0}
        defaultSorted={[{ id: 'order', desc: true }]}
        onSortedChange={(newSorted) => {
          setFilters({
            ...filters,
            selectedSort: newSorted[0].id,
            selectedAscDesc: newSorted[0].desc ? 'desc' : 'asc',
          });
        }}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons
            previous={additionalData?.previous}
            next={additionalData?.next}
            fetchFunction={fetchPaginatedDataDefinitions}
            count={additionalData?.count}
          />
        </span>
      </div>
    </div>
  );
};

PersonnelDataRating.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedWorker: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(PersonnelDataRating));
